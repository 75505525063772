import React from "react";
import ampmMobile from "../../images/ampm--mobile@1x.jpg";
import ampmMobileWebp from "../../images/ampm--mobile@1x.webp";
import ampm from "../../images/ampm@1x.jpg";
import ampmWebp from "../../images/ampm@1x.webp";
import ampmSvg from "../../images/ampm.svg";
import ampmCircle from "../../images/discover-ampm.svg";
import ampmCircleEn from "../../images/discover-ampm-en.svg";
import damixaMobile from "../../images/damixa--mobile@1x.jpg";
import damixaMobileWebp from "../../images/damixa--mobile@1x.webp";
import damixa from "../../images/damixa@1x.jpg";
import damixaWebp from "../../images/damixa@1x.webp";
import damixaSvg from "../../images/damixa.svg";
import damixaCircle from "../../images/discover-damixa--white.svg";
import damixaCircleEn from "../../images/discover-damixa--white-en.svg";
import { Link } from "gatsby";
import {useTranslation} from "react-i18next";

const BrandsShowcase = () => {
  const {t, i18n} = useTranslation();

  return (
    <section className="brands-showcase" id="third">
      <div className="brands-showcase__wrapper">
        <Link
          to="/ampm/"
          className="brands-showcase__section brands-showcase__section--ampm"
        >
          <div className="brands-showcase__image-wrapper image-wrapper">
            <picture>
              <source
                type="image/webp"
                media="(max-width: 1024px)"
                srcSet={ampmMobileWebp}
              />
              <source media="(max-width: 1024px)" srcSet={ampmMobile} />
              <source type="image/webp" srcSet={ampmWebp} />
              <img src={ampm} width="800" height="900" alt="AM.PM" />
            </picture>
            <div className="brands__inner-wrapper">
              <div className="brands-showcase__text">
                <img src={ampmSvg} alt="AM.PM" />
              </div>
              <div className="brands-showcase__link arrow-down arrow-down--right">
                <img src={i18n.language === 'ru' ? ampmCircle : ampmCircleEn} alt="AM.PM" />
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/damixa/"
          className="brands-showcase__section brands-showcase__section--damixa"
        >
          <div className="brands-showcase__image-wrapper image-wrapper">
            <picture>
              <source
                type="image/webp"
                media="(max-width: 1024px)"
                srcSet={damixaMobileWebp}
              />
              <source media="(max-width: 1024px)" srcSet={damixaMobile} />
              <source type="image/webp" srcSet={damixaWebp} />
              <img src={damixa} width="800" height="900" alt="damixa" />
            </picture>
            <div className="brands__inner-wrapper">
              <div className="brands-showcase__text">
                <img src={damixaSvg} alt="damixa" />
              </div>
              <div className="brands-showcase__link arrow-down arrow-down--right">
                <img src={i18n.language === 'ru' ? damixaCircle : damixaCircleEn} alt="damixa" />
              </div>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default BrandsShowcase;
