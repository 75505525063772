import React from "react";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import down from "../../images/to-down--black.svg";
import downEn from "../../images/to-down--black-en.svg";
import downMobile from "../../images/to-down.svg";
import {useTranslation} from "react-i18next";

const About = () => {
  const {t, i18n} = useTranslation();

  return (
    <section className="about" id="second">
      <div className="about__wrapper wrapper">
        <div className="about__text-section">
          <h2 className="about__title title-text">
            {t('lead.secondScreen.mainTitle')}
          </h2>
          <p className="about__lead-text big-text big-text--black i18n">
            {t('lead.secondScreen.bigText')}
          </p>
          <Link className="about__link link-button" to="/about-company/">
            {t('lead.secondScreen.linkText')}
          </Link>
        </div>
        <AnchorLink
          className="about__anchor-wrapper arrow-down--mobile-reverse arrow-down arrow-down--black"
          href="#third"
        >
          <img
            src={i18n.language === 'ru' ? down : downEn }
            width="120"
            height="120"
            alt="Вниз"
            className="about__move-down"
          />

          <img
            src={i18n.language === 'ru' ? downMobile : downEn}
            width="120"
            height="120"
            alt="Вниз"
            className="about__move-down about__move-down--mobile"
          />
        </AnchorLink>
      </div>
    </section>
  );
};

export default About;
