import React from "react";
import career from "../../images/career@1x.jpg";
import { Link } from "gatsby";
import careerWebp from "../../images/career@1x.webp";
import {t} from "i18next";

const Career = () => {
  return (
    <section className="career">
      <div className="career__wrapper wrapper">
        <div className="career__section">
          <h2 className="career__title title-text">{t('lead.careerScreen.preTitle')}</h2>
          <p className="career__lead-text big-text big-text--black" dangerouslySetInnerHTML={
            {__html: t('lead.careerScreen.mainTitle')}
          } />
          <p className="career__sub-text mid-text mid-text--black" dangerouslySetInnerHTML={
            {__html: t('lead.careerScreen.answer')}
          } />
          <Link className="about__link link-button" to="/career/">
            {t('lead.careerScreen.linkText')}
          </Link>
        </div>
        <div className="career__section image-wrapper">
          <picture>
            <source type="image/webp" srcSet={careerWebp} />
            <img
              src={career}
              width="600"
              height="900"
              alt="Карьера в Aquaart Group"
            />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default Career;
